<template>
  <PageLayout :sidebar-links="sidebarLinks" title="Управление" />
</template>

<script>
import { sidebarItems } from "./shared/sidebar";
import PageLayout from "@/components/PageLayout";
export default {
  name: "index",
  components: {
    PageLayout,
  },
  data: () => ({
    sidebarLinks: sidebarItems,
  }),
};
</script>
